<template>
	<div class="order" :class="{ 'outline': helper.isCompletedOrder(content.statusCode) || content.statusCode === constants.ORDER_STATUS.CANCEL }">
        <div class="detail">
            <div class="top">
                <div class="data">
                    <label>หมายเลขคำสั่งซื้อ</label>
                    <span class="text-order-no">{{ content.orderNumber }}</span>
                </div>
                <div class="data">
                    <label>วันที่ทำรายการ</label>
                    <span>{{ content.orderDate }}</span>
                </div>
            </div>
            <div class="product">
                <div class="info">
                    <img :src="content.products[0].picture" :alt="content.products[0].name" :title="content.products[0].name" @error="helper.setNoImg($event, 'product')" />
                    <div class="detail">
                        <div class="item" v-for="(item, index) in content.products" :key="index">
                            <span class="fix-size">
                                ขนาดยาง {{ item.size }}
                            </span>
                            <span class="quantity">
                                จำนวน {{ item.quantity }} เส้น
                            </span>
                            <p>{{ item.brand }} {{ item.name }} (ปี {{ item.year }})</p>
                        </div>
                        <div class="summary" v-if="content.isPrepaid">
                            <div class="data fix-size">
                                <label>ยอดมัดจำ</label>
                                <span>฿ {{ content.prepaidPrice }}</span>
                            </div>
                            <div class="data">
                                <label>ยอดที่เหลือหลังมัดจำ</label>
                                <span>฿ {{ content.remainPrice }}</span>
                            </div>
                        </div>
                        <div class="summary">
                            <div class="data fix-size">
                                <label>ยอดรวม</label>
                                <span>฿ {{ content.orderTotal }}</span>
                            </div>
                            <div class="data">
                                <label>ช่องทางการชำระเงิน</label>
                                <span>{{ content.paymentMethod }}</span>
                            </div>
                        </div>
                        <div class="tax" v-if="content.customer.requireTax">
                            <span v-if="content.customer.requireElecTax">
                                <b-icon icon="check-square" class="icon"></b-icon>
                                ต้องการใบกำกับภาษีอิเล็กทรอนิกส์ (e-Tax) ส่งที่: <i>{{ content.customer.taxEmail }}</i>
                            </span>
                            <span v-else>
                                <b-icon icon="check-square" class="icon"></b-icon>
                                ต้องการใบกำกับภาษี
                            </span>
                            <div class="tax-info">
                                {{ content.customer.taxName }}
                                <br />
                                ที่อยู่ {{ content.customer.taxAddress }} {{ content.customer.taxSubDistrict }} {{ content.customer.taxDistrict }} {{ content.customer.taxProvince }} {{ content.customer.taxPostCode }}
                                <br />
                                เลขประจำตัวผู้เสียภาษี {{ content.customer.taxId }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="customer">
                    {{ content.customer.firstName }} {{ content.customer.lastName }} / โทร. {{ content.customer.tel }} / {{ content.customer.licenseNumber }} {{ content.customer.licenseProvince }} / {{ content.customer.carBrand }} {{ content.customer.carModel }}
                </div>
                <template v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                    <div class="service">
                        <label>นัดหมาย</label>
                        <span>{{ content.appointmentDateText }} - {{ content.appointmentTime }}</span>
                    </div>
                    <div class="service" v-if="content.storeData.branchName">
                        <label>สาขาที่บริการ</label>
                        <span>{{ content.storeData.branchName }}</span>
                    </div>
                </template>
                <template v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                    <div class="service" v-if="content.shippingData">
                        <label>จัดส่งถึง</label>
                        <span>{{ content.shippingData.receiverName }} ติดต่อ {{ content.shippingData.receiverTel}}</span>
                    </div>
                    <div class="service" v-if="content.shippingData">
                        <label>ที่อยู่ผู้รับ</label>
                        <span>{{ content.shippingData.receiverAddress }}</span>
                    </div>
                </template>
            </div>
        </div>
        <div class="status" v-if="mode === 'list'">
            <div class="status-text">
                {{ content.statusText }}
                <br />
                <a :href="helper.replaceRouteParam(routerPath.SELLER_RECEIPT, ':id', content.id)" target="_blank">
                    <b-icon icon="card-text" class="icon"></b-icon> 
                    <span v-if="content.orderType === constants.ORDER_TYPE.SERVICE">เปิดดูใบนัดหมายติดตั้ง</span>
                    <span v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">เปิดดูใบรายการสั่งซื้อ</span>
                </a>
                <a :href="helper.replaceRouteParam(routerPath.SELLER_DELIVERY_COVER, ':id', content.id)" target="_blank" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                    <b-icon icon="truck" class="icon"></b-icon> 
                    <span>ปริ๊นใบปะหน้าจัดส่ง</span>
                </a>
            </div>

            <div class="operation">
                <template v-if="content.statusCode === constants.ORDER_STATUS.WAITING_CONFIRM">
                    <button class="btn btn-main btn-confirm" @click="confirmOrder()">ยืนยัน</button>
                    <button class="btn btn-cancel" @click="cancelOrder()">ยกเลิก</button>
                </template>
                <template v-if="content.statusCode === constants.ORDER_STATUS.WAITING_SERVICE">
                    <router-link :to="helper.replaceRouteParam(routerPath.SELLER_ORDER_DETAIL, ':id', content.id)" class="btn btn-main btn-confirm">ยืนยันการติดตั้ง</router-link>
                </template>
                <template v-if="content.statusCode === constants.ORDER_STATUS.WAITING_DELIVERY">
                    <router-link :to="helper.replaceRouteParam(routerPath.SELLER_ORDER_DETAIL, ':id', content.id)" class="btn btn-main btn-confirm">ยืนยันการจัดส่ง</router-link>
                </template>
                <template v-if="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE">
                    <div class="note">
                        <p>{{ displayDateFormat(content.closedDate) }} เวลา {{ content.closedTime }}</p>
                        <p>โดย {{ content.updatedBy }}</p>
                        <p>เลขไมล์ {{ content.customer.carDistance }}</p>
                        <p>DOT {{ content.dots }}</p>
                        <router-link :to="helper.replaceRouteParam(routerPath.SELLER_ORDER_DETAIL, ':id', content.id)">ดูรายละเอียด</router-link>
                    </div>
                </template>
                <template v-if="content.statusCode === constants.ORDER_STATUS.COMPLETED_DELIVERY">
                    <div class="note">
                        <p>{{ displayDateFormat(content.closedDate) }} เวลา {{ content.closedTime }}</p>
                        <p>{{ content.deliveryData.name }} เลขที่ {{ content.deliveryData.ref }}</p>
                        <p>DOT {{ content.dots }}</p>
                        <router-link :to="helper.replaceRouteParam(routerPath.SELLER_ORDER_DETAIL, ':id', content.id)">ดูรายละเอียด</router-link>
                    </div>
                </template>
                <template v-if="content.statusCode === constants.ORDER_STATUS.CANCEL">
                    <div class="note">
                        <p>{{ displayDateFormat(content.closedDate) }} เวลา {{ content.closedTime }}</p>
                        <p>โดย {{ content.updatedBy }}</p>
                    </div>
                </template>
            </div>
        </div>
	</div>
</template>

<script>
import moment from 'moment';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import SellerService from '@/services/sellerService';
import OptionActiveStatus from '@/services/staticOption/activeStatus';

export default {
	data() {
		return {
            routerPath: RouterPath,
            constants: Constants,
            helper: Helper,
			form: null,
			error: null,
			optionStatus: OptionActiveStatus.option,
			isOpenAuthorityModal: false
		}
	},
	props: {
        content: {
            type: Object,
            default: () => null
        },
        mode: {
            type: String,
            default: () => 'list'
        }
	},
	methods: {
        displayDateFormat(date) {
            return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        confirmOrder() {
            this.$swal(
                Helper.confirmAlert('ยืนยันคำสั่งซื้อ', 'หมายเลขคำสั่งซื้อ '  + this.content.orderNumber)
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
                    const result = await SellerService.confirmOrder(this.getOrderParam());
                    loader.hide();

                    if (result.data?.success) {
                        this.$swal(Helper.completeAlert('รายการสั่งซื้อได้รับการยืนยันเรียบร้อย', 'หมายเลขคำสั่งซื้อ '  + this.content.orderNumber));
                        this.$emit('updateOrder');
                    } else {
                        this.$swal(Helper.warningAlert('ไม่สามารถยืนยันคำสั่งซื้อได้', result.data?.message));
                    }
                }
            });
        },
        cancelOrder() {
            this.$swal(
                Helper.confirmAlert('ยกเลิกคำสั่งซื้อ', 'หมายเลขคำสั่งซื้อ '  + this.content.orderNumber)
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
                    const result = await SellerService.cancelOrder(this.getOrderParam());
                    loader.hide();

                    if (result.data?.success) {
                        this.$swal(Helper.completeAlert('รายการสั่งซื้อได้รับการยกเลิกเรียบร้อย', 'หมายเลขคำสั่งซื้อ '  + this.content.orderNumber));
                        this.$emit('updateOrder');
                    } else {
                        this.$swal(Helper.warningAlert('ไม่สามารถยกเลิกคำสั่งซื้อได้', result.data?.message));
                    }
                }
            });
        },
        getOrderParam() {
            return {
                id: this.content.id
            };
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.order {
    background: #ebebeb;
    padding: 10px;
    font-size: 13px;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: $screenLarge) {
        display: block;
    }

    &.outline {
        background: #fff;
        border: 1px solid #ccc;
    }

    .detail {
        flex: 1;

        .top {
            display: flex;

            @media only screen and (max-width: $screenMedium) {
                padding-bottom: 10px;
                border-bottom: 1px dashed #bbb;
            }

            @media only screen and (max-width: $screenSmall) {
                justify-content: center;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
            }

            .data {
                display: flex;
                width: 320px;

                @media only screen and (max-width: $screenLarge) {
                    width: 290px;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    width: 100%;
                }

                label {
                    font-weight: bold;
                    margin: 0 8px 0 0;
                }

                .text-order-no {
                    color: #dd7921;
                }
            }
        }

        .product {
            display: flex;
            justify-content: space-between;
            line-height: 20px;
            margin-top: 7px;

            @media only screen and (max-width: $screenMedium) {
                margin-top: 12px;
            }
            
            .info {
                display: flex;
                align-items: flex-start;
                flex: 1;

                @media only screen and (max-width: $screenExtraSmall) {
                    display: block;
                    text-align: center;
                }

                img {
                    display: block;
                    margin-right: 15px;
                    width: 120px;
                    border: 1px solid #ccc;

                    @media only screen and (max-width: $screenExtraSmall) {
                        width: 100%;
                        max-width: 180px;
                        margin: 0 auto 10px;
                    }
                }

                .detail {
                    .fix-size {
                        width: 185px;
                    }

                    .item {
                        display: flex;
                        flex-wrap: wrap;
                        border-bottom: 1px solid #999;
                        padding-bottom: 15px;

                        @media only screen and (max-width: $screenSmall) {
                            padding-bottom: 10px;
                        }

                        @media only screen and (max-width: $screenExtraSmall) {
                            display: block;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        & + .item {
                            padding-top: 15px;

                            @media only screen and (max-width: $screenSmall) {
                                padding-top: 10px;
                            }
                        }

                        .quantity {
                            flex: 1;
                        }

                        p {
                            width: 100%;
                            margin: 5px 0 0;
                        }
                    }

                    .summary {
                        display: flex;
                        margin-top: 10px;

                        & + .summary {
                            margin-top: 0;
                        }

                        .data {
                            @media only screen and (max-width: $screenExtraSmall) {
                                width: 50%;
                            }
                            
                            label {
                                font-weight: bold;
                                margin: 0 8px 0 0;

                                @media only screen and (max-width: $screenExtraSmall) {
                                    display: block;
                                }
                            }

                            span {
                                color: #b34343;

                                @media only screen and (max-width: $screenExtraSmall) {
                                    display: block;
                                }
                            }
                        }
                    }

                    .tax {
                        margin-top: 10px;
                        line-height: normal;

                        span {
                            display: block;
                            margin-top: 3px;

                            .icon {
                                margin-right: 3px;
                            }
                        }

                        .tax-info {
                            margin-top: 6px;
                            font-size: 12px;
                            color: #777;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .bottom {
            margin-top: 10px;

            @media only screen and (max-width: $screenMedium) {
                text-align: center;
            }

            .service {
                label {
                    margin: 0 5px 0 0;
                    font-weight: bold;
                }
            }
        }
    }

    .status {
        @media only screen and (max-width: $screenLarge) {
            margin-top: 10px;
            padding-top: 10px;
            align-items: center;
            border-top: 1px dashed #bbb;
        }

        .status-text {
            font-size: 18px;
            font-weight: bold;
            line-height: 20px;
            text-align: right;
            margin-bottom: 15px;

            @media only screen and (max-width: $screenLarge) {
                text-align: center;
                margin-bottom: 10px;
            }

            a {
                display: block;
                font-size: 14px;
                font-weight: normal;
                text-align: right;
                color: #666;

                .icon {
                    margin-right: 5px;
                }
            }
        }

        .operation {
            width: 200px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-left: 30px;

            @media only screen and (max-width: $screenLarge) {
                margin: auto;
            }

            .btn {
                width: 100%;
                padding: 5px;
                font-weight: bold;
                @include borderRadius(0);
                border: 1px solid #333;
            }

            .note {
                padding: 10px;
                border: 1px solid #333;
                background: $mainColor;
                width: 100%;
                font-size: 13px;

                p {
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>