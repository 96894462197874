const option = [
    { id: 'active', name: 'ใช้งาน'},
    { id: 'inactive', name: 'ปิดการใช้งาน'}
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};