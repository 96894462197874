<template>
	<div class="confirm-service" v-if="form && error">
		<section class="form">
			<div class="row">
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field inline">
						<label>จัดส่งวันที่</label>
						<input type="text"
							readonly
							:value="field.date"
							class="readonly square" />
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field inline">
						<label>เวลา</label>
						<div class="input-time">
							<timepicker
								v-model="form.time"
								:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_DELIVERY"
								:inputClass="[ 'square', (error.time ? 'invalid' : '') ]">
							</timepicker>
						</div>
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field inline">
						<label>User</label>
						<input type="text"
							readonly
							:value="field.user"
							class="readonly square" />
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col col-12 col-sm-6 col-md-4">
					<div class="input-field inline">
						<label>ขนส่งโดย</label>
						<dropdown
							v-model="form.deliveryCompany"
							placeholder="-- เลือกบริษัทขนส่ง --"
							:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_DELIVERY"
							class="input-option"
							:classes="{ input: [error.deliveryCompany ? 'invalid' : '', 'square'] }"
							:options="option.deliveryCompany">
						</dropdown>
					</div>
				</div>
				<div class="col col-12 col-sm-6 col-md-8">
					<div class="input-field inline">
						<label>หมายเลขพัสดุ</label>
						<div class="input-with-note">
							<input type="text"
								v-model="form.deliveryRef"
								maxlength="500"
								placeholder="หากสินค้าแยกหมายเลขพัสดุ โปรดใส่ , ขั้น"
								:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_DELIVERY"
								class="square"
								:class="{ 'invalid': error.deliveryRef }" />
							<span class="note mt-1">* จัดส่งโดยผู้ขำย ให้ใส่ทะเบียนรถที่จัดส่ง</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col col-12">
					<div class="input-field inline">
						<label>DOT ยาง</label>
						<div class="dots">
							<div class="item" v-for="(item, index) in form.dots" :key="index">
								<input type="tel"
									@keypress="$AppUtility.inputOnlyNumber($event)"
									v-model="form.dots[index].dot"
									maxlength="4"
									:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_DELIVERY"
									class="square"
									:class="{ 'invalid': error.dots[index].dot }" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col col-sm-6 col-md-3" v-for="(item, index) in form.dots" :key="'dot' + index">
					<div class="input-picture" :class="{ 'invalid': error.dots[index].picture }">
						<template v-if="form.dots[index].picture">
							<button @click="setDot('', index)" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form.dots[index].picture" @click="openFullPicture(form.dots[index].picture)" />
							</div>
						</template>
					</div>
					<take-picture
						v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY"
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setDot($event, index)"
						:aspectRatio="4 / 3"
					>รูปภาพ DOT ยาง-{{ index + 1 }}</take-picture>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<span class="remark">
						* จัดส่งโดยผู้ขาย ให้ถ่ายรูปรถและทะเบียนรถที่จัดส่ง
						<br />
						** หากใช้ขนส่งโดย Kerry กรุณาถ่ายหมายเลขพัสดุของแต่ละเส้น
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col col-sm-6 col-md-3" v-for="(item, index) in form.dots" :key="'track' + index">
					<div class="input-picture" :class="{ 'invalid': error.dots[index].tracking }">
						<template v-if="form.dots[index].tracking">
							<button @click="setTracking('', index)" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form.dots[index].tracking" @click="openFullPicture(form.dots[index].tracking)" />
							</div>
						</template>
					</div>
					<take-picture
						v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY"
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setTracking($event, index)"
						:aspectRatio="4 / 3"
					>หมายเลขพัสดุ {{ index + 1 }}</take-picture>
				</div>
				<div class="col col-sm-6 col-md-3">
					<div class="input-picture" :class="{ 'invalid': error.overallPicture }">
						<template v-if="form.overallPicture">
							<button @click="setOverallProduct('')" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form.overallPicture" @click="openFullPicture(form.overallPicture)" />
							</div>
						</template>
					</div>
					<take-picture
						v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY"
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setOverallProduct($event)"
						:aspectRatio="4 / 3"
					>รูปรวมยางชุด</take-picture>
				</div>
			</div>
		</section>

		<div class="text-center mt-4" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_DELIVERY">
			<button class="btn btn-main btn-submit" @click="submitConfirm('delivery')">ยืนยันการจัดส่ง</button>
		</div>

		<modal-full-picture
            :pictureList="fullPicture"
            :isDisplay="isShowFullPicture"
            @close="closeFullPicture()">
        </modal-full-picture>
	</div>
</template>

<script>
import moment from 'moment';
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import OptionDeliveryCompany from '@/services/staticOption/deliveryCompany';
import MixinConfirmOrder from '@/components/seller/mixins/confirmOrder';

export default {
	mixins: [ MixinConfirmOrder ],
	data() {
		return {
			constants: Constants,
			field: null,
			form: null,
			error: null,
			option: {
				deliveryCompany: OptionDeliveryCompany.option
			},
			isShowFullPicture: false,
            fullPicture: []
		}
	},
	methods: {
		initPage() {
			this.field = this.initStatic();
			this.form = this.initForm();
			this.error = this.initError();
		},
		initStatic() {
			return {
				date: moment().format('DD-MM-YYYY'),
				user: this.content.staffName
			};
		},
		initForm() {
			const form = {
				id: this.content.id,
				time: this.content.serviceTime,
				dots: [],
				overallPicture: this.content.overallPicture,
				deliveryCompany: this.content.deliveryData ? this.content.deliveryData.name : '',
				deliveryRef: this.content.deliveryData ? this.content.deliveryData.ref : ''
			};

			for (let index = 0; index < this.countQuantity(); index++) {
				const dots = this.content.dots.split('/');
				const pictures = this.content.dotPictures;
				const tracking = this.content.trackingPictures;

				form.dots.push({
					dot: dots.length >= (index + 1) ? dots[index] : '',
					picture: dots.length >= (index + 1) ? pictures['dot' + (index + 1)] : '',
					tracking: dots.length >= (index + 1) ? tracking['tracking' + (index + 1)] : ''
				});
			}

			return form;
		},
		initError() {
			const error = {
                time: false,
                dots: [],
				overallPicture: false,
                deliveryCompany: false,
				deliveryRef: false
            };

			for (let index = 0; index < this.countQuantity(); index++) {
				error.dots.push({
					dot: false,
					picture: false,
					tracking: false
				});
			}

			return error;
        },
		setDot(value, index) {
            this.form.dots[index].picture = value;
        },
		setTracking(value, index) {
            this.form.dots[index].tracking = value;
        },
		setOverallProduct(value) {
            this.form.overallPicture = value;
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
			let isNoPicture = false;

            // Check Blank
            if (!this.form.time?.trim() || this.form.time?.indexOf('HH') > -1 || this.form.time?.indexOf('mm') > -1) {
				this.error.time = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.deliveryCompany?.trim()) {
				this.error.deliveryCompany = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.deliveryRef?.trim()) {
				this.error.deliveryRef = true;
				isValid = false;
				isBlank = true;
			}
			this.form.dots.forEach((item, index) => {
				if (!item.dot) {
					this.error.dots[index].dot = true;
					isValid = false;
					isBlank = true;
				}
				if (!item.picture) {
					this.error.dots[index].picture = true;
					isValid = false;
					isNoPicture = true;
				}
				if (index === 0) {
					if (!item.tracking) {
						this.error.dots[index].tracking = true;
						isValid = false;
						isNoPicture = true;
					}
				}
			});
			if (!this.form.overallPicture) {
				this.error.overallPicture = true;
				isValid = false;
				isNoPicture = true;
			}

            // Popup
			const title = 'ยังไม่สามารถยืนยันการจัดส่งได้';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกข้อมูลในกล่องสีแดงให้ครบถ้วน'));
            } else if (isNoPicture) {
                this.$swal(Helper.warningAlert(title, 'โปรดลงรูปภาพให้ครบ'));
            }

            return isValid;
        },
		openFullPicture(image) {
            this.fullPicture = [];
            this.fullPicture.push(image);

            this.isShowFullPicture = true;
        },
        closeFullPicture() {
            this.isShowFullPicture = false;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ConfirmOrder.scss';
</style>