import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import SellerService from '@/services/sellerService';

export default  {
    props: {
        content: Object
    },
	mounted() {
		this.initPage();
	},
    methods: {
        countQuantity() {
			let countQuantity = 0;

			this.content.products.forEach((item) => {
				countQuantity += item.quantity;
			});

			return countQuantity;
		},
		async submitConfirm(orderType) {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
				const result = orderType == 'service' ?
									await SellerService.confirmService(this.form) :
									await SellerService.confirmDelivery(this.form);
				loader.hide();

				if (result.data?.success) {
					if (window.history.length > 0) {
                        this.$router.go(-1);
                    } else {
                        this.$router.push(RouterPath.SELLER_MAIN);
                    }
				} else {
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data?.message));
				}
			}
        },
    }
}