<template>
    <section v-if="content" :class="{ 'job-print': mode === 'print' }">
        <h2 v-if="content.orderType === constants.ORDER_TYPE.SERVICE">ใบนัดหมายติดตั้ง (Installation)</h2>
        <h2 v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">ใบสั่งซื้อจัดส่งสินค้า (Purchase Order)</h2>

        <div class="summary">
            <div class="column">
                <div class="box">
                    <h3>รายการสินค้า</h3>
                    <div class="info">
                        <ul>
                            <li v-for="(product, index) in content.products" :key="'product' + index">
                                {{ product.fullName }} (ปี {{ product.year }}) จำนวน {{ product.quantity }} เส้น
                            </li>
                            <li v-for="(service, index) in content.services" :key="'service' + index">
                                {{ service.title }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column" v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                <div class="box">
                    <h3>ข้อมูลลูกค้า</h3>
                    <div class="info">
                        <ul>
                            <li>
                                {{ content.customer.firstName }} {{ content.customer.lastName }}
                                <br />
                                โทร. {{ content.customer.tel }}
                            </li>
                            <li>
                                {{ content.customer.licenseNumber }} {{ content.customer.licenseProvince }}
                                <br />
                                {{ content.customer.carBrand }} {{ content.customer.carModel }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                <div class="box">
                    <h3>ข้อมูลการจัดส่ง</h3>
                    <div class="info">
                        <ul>
                            <li>
                                สั่งโดย {{ content.customer.firstName }} {{ content.customer.lastName }}
                                <br />
                                โทร. {{ content.customer.tel }}
                            </li>
                            <li>
                                ส่งถึง {{ content.shippingData.receiverName }}
                                <br />
                                {{ content.shippingData.receiverAddress }}
                                <br />
                                โทร. {{ content.shippingData.receiverTel }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <h3>ข้อมูลร้านค้า</h3>
                    <div class="info">
                        <ul>
                            <li>
                                {{ content.storeData.name }}
                                <br />
                                {{ content.storeData.address }}
                            </li>
                            <li v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                                วันเวลานัดหมาย
                                <br />
                                {{ content.appointmentDateText }}
                                <br />
                                {{ content.appointmentTime }}
                            </li>
                            <li v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                                <template v-if="content.deliveryData && content.deliveryData.ref">
                                    จัดส่งโดย {{ content.deliveryData.name }}
                                    <br />
                                    หมายเลขพัสดุ <span v-html="splitDeliveryTracking(content.deliveryData.ref)"></span>
                                </template>
                                <template v-else>
                                    อยู่ระหว่างการจัดเตรียมสินค้า
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';

export default {
    data() {
        return {
            constants: Constants
        }
    },
    props: {
		content: Object,
        mode: String
	},
    methods: {
        splitDeliveryTracking(text) {
           return Helper.splitDeliveryTracking(text);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.job-print {
    * {
        font-family: 'Tahoma';
        font-size: 11px;
        line-height: 13px;
    }

    h2 {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .summary {
        .column {
            .box {
                h3 {
                    font-size: 12px;
                }

                .info {
                    font-size: 11px;
                    line-height: 13px;

                    ul {
                        margin: 0;
                        padding-left: 15px;

                        span {
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }
    }
}

h2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 22px;
}

.summary {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .column {
        width: 33%;
        padding: 0 10px;
        flex: 1;

        @media only screen and (max-width: $screenSmall) {
            flex: none;
            width: 50%;

            &:first-child {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 100%;
            margin-bottom: 20px;
        }

        .box {
            color: #555;
            border: 1px solid #555;
            height: 100%;

            h3 {
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                padding: 5px 10px;
                margin: 0;
                border-bottom: 1px solid #555;

                @media only screen and (max-width: $screenMedium) {
                    font-size: 16px;
                }
            }

            .info {
                padding: 10px;
                font-size: 14px;

                ul {
                    margin-bottom: 0;

                    span {
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}
</style>