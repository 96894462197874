<template>
	<div class="confirm-service" v-if="form && error">
		<section class="form">
			<div class="row">
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>ติดตั้งวันที่</label>
						<input type="text"
							readonly
							:value="field.date"
							class="readonly square" />
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>เวลา</label>
						<div class="input-time">
							<timepicker
								v-model="form.time"
								:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
								:inputClass="[ 'square', (error.time ? 'invalid' : ''), (content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE ? 'readonly' : '') ]">
							</timepicker>
						</div>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>User</label>
						<input type="text"
							readonly
							:value="field.user"
							class="readonly square" />
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>ทะเบียนรถ</label>
						<input type="text"
							@keypress="$AppUtility.restrictInputLicensePlate($event)"
							v-model="form.licenseNumber"
							maxlength="10"
							placeholder="กกXXXX"
							:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							class="square"
							:class="[ (error.licenseNumber ? 'invalid' : ''), (content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE ? 'readonly' : '') ]" />
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>จังหวัด</label>
						<dropdown
							v-model="form.licenseProvince"
							placeholder="-- เลือกจังหวัด --"
							:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							:options="option.licenseProvince"
							class="input-option"
							:classes="{ input: [error.licenseProvince ? 'invalid' : '', 'square'] }">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>เลขไมล์</label>
						<input type="tel"
							@keypress="$AppUtility.inputOnlyNumber($event)"
							v-model="form.carDistance"
							:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							maxlength="6"
							placeholder="10000"
							class="square"
							:class="[ (error.carDistance ? 'invalid' : ''), (content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE ? 'readonly' : '') ]" />
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>ยี่ห้อ</label>
						<dropdown
							v-model="form.carBrand"
							placeholder="ยี่ห้อรถ"
							:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							:options="option.carBrand"
							class="input-option"
							:classes="{ input: [error.carBrand ? 'invalid' : '', 'square'] }"
							@change="changeBrand($event)">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>รุ่นรถ</label>
						<dropdown
							v-model="form.carModel"
							placeholder="รุ่นรถ"
							:disabled="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							:options="option.carModel"
							class="input-option"
							:classes="{ input: [error.carModel ? 'invalid' : '', 'square'] }">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field inline">
						<label>สีรถ</label>
						<input type="text"
							v-model="form.carColor"
							maxlength="20"
							:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
							placeholder="ขาว/ดำ/เทา"
							class="square"
							:class="[ (error.carColor ? 'invalid' : ''), (content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE ? 'readonly' : '') ]" />
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col col-12">
					<div class="input-field inline">
						<label>DOT ยาง</label>
						<div class="dots">
							<div class="item" v-for="(item, index) in form.dots" :key="index">
								<input type="tel"
									@keypress="$AppUtility.inputOnlyNumber($event)"
									v-model="form.dots[index].dot"
									maxlength="4"
									:readonly="content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE"
									:placeholder="'เส้นที่ ' + (index + 1)"
									class="square"
									:class="[ (error.dots[index].dot ? 'invalid' : ''), (content.statusCode === constants.ORDER_STATUS.COMPLETED_SERVICE ? 'readonly' : '') ]" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col col-6 col-md-3" v-for="(item, index) in pictures" :key="'picture' + index">
					<div class="input-picture" :class="{ 'invalid': error.picture[item.property] }">
						<template v-if="form.picture[item.property]">
							<button @click="setPicture('', item.property)" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form.picture[item.property]" @click="openFullPicture(form.picture[item.property])" />
							</div>
						</template>
					</div>
					<take-picture
						v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE"
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setPicture($event, item.property)"
						:aspectRatio="4 / 3"
					>{{ item.name }}</take-picture>
				</div>

				<div class="col col-6 col-md-3" v-for="(item, index) in form.dots" :key="'dot' + index">
					<div class="input-picture" :class="{ 'invalid': error.dots[index].picture }">
						<template v-if="form.dots[index].picture">
							<button @click="setDot('', index)" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form.dots[index].picture" @click="openFullPicture(form.dots[index].picture)" />
							</div>
						</template>
					</div>
					<take-picture
						v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE"
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setDot($event, index)"
						:aspectRatio="4 / 3"
					>รูปภาพ DOT ยาง-{{ index + 1 }}</take-picture>
				</div>
			</div>
		</section>

		<div class="text-center mt-4" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE">
			<button class="btn btn-main btn-submit" @click="submitConfirm('service')" v-if="content.statusCode !== constants.ORDER_STATUS.COMPLETED_SERVICE">ยืนยันการติดตั้ง</button>
		</div>

		<modal-full-picture
            :pictureList="fullPicture"
            :isDisplay="isShowFullPicture"
            @close="closeFullPicture()">
        </modal-full-picture>
	</div>
</template>

<script>
import moment from 'moment';
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import OptionLicenseProvince from '@/services/staticOption/licenseProvince';
import MixinConfirmOrder from '@/components/seller/mixins/confirmOrder';

export default {
	mixins: [ MixinConfirmOrder ],
	async mounted() {
        await this.initCarBrandOption();
    },
	data() {
		return {
			constants: Constants,
			field: null,
			form: null,
			error: null,
			option: {
                licenseProvince: OptionLicenseProvince.option,
                carBrand: [],
                carModel: []
            },
			pictures: [
				{ property: 'carFront', name: 'ด้านหน้ารถ' },
				{ property: 'carBack', name: 'ด้านท้ายรถ' },
				{ property: 'carDistance', name: 'เลขไมล์' },
				{ property: 'compulsory', name: 'ป้ายวงกลม' }
			],
			isShowFullPicture: false,
            fullPicture: []
		}
	},
	methods: {
		initPage() {
			this.field = this.initStatic();
			this.form = this.initForm();
			this.error = this.initError();
		},
		initStatic() {
			let installDate = this.content.closedDate ? moment(this.content.closedDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');

			return {
				date: installDate,
				user: this.content.staffName
			};
		},
		initForm() {
			const form = {
				id: this.content.id,
				time: this.content.serviceTime,
				licenseNumber: this.content.customer.licenseNumber,
                licenseProvince: this.content.customer.licenseProvince,
				carDistance: this.content.carDistance,
                carBrand: this.content.customer.carBrand,
                carModel: this.content.customer.carModel,
				carColor: this.content.customer.carColor,
				dots: [],
				picture: {}
			};

			for (let index = 0; index < this.pictures.length; index++) {
				form.picture[this.pictures[index].property] = this.content.carPictures[this.pictures[index].property];
			}

			for (let index = 0; index < this.countQuantity(); index++) {
				const dots = this.content.dots.split('/');
				const pictures = this.content.dotPictures;
				
				form.dots.push({
					dot: dots.length >= (index + 1) ? dots[index] : '',
					picture: dots.length >= (index + 1) ? pictures['dot' + (index + 1)] : ''
				});
			}

			return form;
		},
		initError() {
			const error = {
                time: false,
                licenseNumber: false,
                licenseProvince: false,
				carDistance: false,
                carBrand: false,
                carModel: false,
				carColor: false,
                dots: [],
				picture: {}
            };

			for (let index = 0; index < this.pictures.length; index++) {
				error.picture[this.pictures[index].property] = false;
			}

			for (let index = 0; index < this.countQuantity(); index++) {
				error.dots.push({
					dot: false,
					picture: false
				});
			}

			return error;
        },
		async initCarBrandOption() {
            const result = await MasterService.getCarBrand();

            this.option.carBrand = Helper.setupOption(result.data);
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getCarModel(brand);
                loader.hide();

                this.option.carModel = Helper.setupOption(result.data);
            } else {
                this.option.carModel = [];
            }

            this.form.carModel = this.option.carModel.find(x => x.id === this.form.carModel) ? this.form.carModel : '';
        },
		setPicture(value, property) {
            this.form.picture[property] = value;
        },
		setDot(value, index) {
            this.form.dots[index].picture = value;
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
			let isNoPicture = false;

            // Check Blank
            if (!this.form.time) {
				this.error.time = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.licenseNumber?.trim()) {
				this.error.licenseNumber = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.licenseProvince?.trim()) {
				this.error.licenseProvince = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.carDistance?.trim()) {
				this.error.carDistance = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.carBrand?.trim()) {
				this.error.carBrand = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.carModel?.trim()) {
				this.error.carModel = true;
				isValid = false;
				isBlank = true;
			}
			if (!this.form.carColor?.trim()) {
				this.error.carColor = true;
				isValid = false;
				isBlank = true;
			}
			this.form.dots.forEach((item, index) => {
				if (!item.dot) {
					this.error.dots[index].dot = true;
					isValid = false;
					isBlank = true;
				}
				if (!item.picture) {
					this.error.dots[index].picture = true;
					isValid = false;
					isNoPicture = true;
				}
			});

			// Check Picture
			for (const property in this.error.picture) {
                if (!this.form.picture[property]?.trim()) {
                    this.error.picture[property] = true;
                    isValid = false;
                    isNoPicture = true;
                }
            }

            // Popup
			const title = 'ยังไม่สามารถยืนยันการติดตั้งได้';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกข้อมูลในกล่องสีแดงให้ครบถ้วน'));
            } else if (isNoPicture) {
                this.$swal(Helper.warningAlert(title, 'โปรดลงรูปภาพให้ครบ'));
            }

            return isValid;
        },
		openFullPicture(image) {
            this.fullPicture = [];
            this.fullPicture.push(image);

            this.isShowFullPicture = true;
        },
        closeFullPicture() {
            this.isShowFullPicture = false;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ConfirmOrder.scss';
</style>