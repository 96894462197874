<template>
	<div>
		<h2>{{ title }}</h2>

		<div class="loading" v-if="!isContentReady">
			<div class="item">
				<loader-content :isDisplay="true"></loader-content>
			</div>
		</div>

		<template v-if="isContentReady">
			<seller-order-card
				class="mb-3"
				mode="detail"
				:content="fields">
			</seller-order-card>

			<order-job-summary
				class="mb-4"
				:content="fields">
			</order-job-summary>

			<seller-confirm-service
				v-if="fields.orderType === constants.ORDER_TYPE.SERVICE"
				:content="fields">
			</seller-confirm-service>

			<seller-confirm-delivery
				v-if="fields.orderType === constants.ORDER_TYPE.DELIVERY"
				:content="fields">
			</seller-confirm-delivery>
		</template>
	</div>
</template>

<script>
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import SellerService from '@/services/sellerService';
import SellerOrderCard from '@/components/seller/orders/OrderCard';
import OrderJobSummary from '@/components/order/JobSummary';
import SellerConfirmService from '@/components/seller/orders/ConfirmService';
import SellerConfirmDelivery from '@/components/seller/orders/ConfirmDelivery';

export default {
	components: {
		SellerOrderCard,
		OrderJobSummary,
		SellerConfirmService,
		SellerConfirmDelivery
	},
	data() {
		return {
			constants: Constants,
			isContentReady: false,
			title: ''
		}
	},
	mounted() {
		this.getOrderDetail();
	},
	methods: {
		async getOrderDetail() {
			this.isContentReady = false;

			const param = this.setupParam();
            const result = await SellerService.getOrderDetail(param);

			if (result.data?.success) {
				this.fields = result.data.content;
				this.isContentReady = true;
				this.title = this.getTitle(this.fields.orderType);
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
		},
		getTitle(type) {
			let title = '';

			switch (type) {
				case 1:
					title = 'ยืนยันการติดตั้ง';
					break;

				case 2:
					title = 'ยืนยันการจัดส่ง';
					break;
			}

			return title;
		},
		setupParam() {
			const param = {
				id: this.$route.params.id
			};

			return param;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 22px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 20px;
}

.loading {
	.item {
		height: 300px;
		margin-bottom: 20px;
	}
}
</style>