const option = [
    { id: 'ไปรษณีย์ไทย', name: 'ไปรษณีย์ไทย'},
    { id: 'Kerry', name: 'Kerry'},
    { id: 'Flash Express', name: 'Flash Express'},
    { id: 'DHL Express', name: 'DHL Express'},
    { id: 'SCG Express', name: 'SCG Express'},
    { id: 'J&T Express', name: 'J&T Express'},
    { id: 'Nim Express', name: 'Nim Express'},
    { id: 'Best Express', name: 'Best Express'},
    { id: 'Ninja van', name: 'Ninja van'},
    { id: 'Business-idea', name: 'Business-idea'},
    { id: 'บริษัทขนส่งเอง', name: 'บริษัทขนส่งเอง'},
    { id: 'อื่นๆ', name: 'อื่นๆ'}
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};